<template>
  <div class="hello container">
      <vs-row
        class="vertical-center"
        vs-align="flex-start"
        vs-type="flex" vs-justify="center" vs-w="12" vs-sm="12" vs-xs="12">
          <vs-col id="main-col" vs-justify="center" vs-align="center" vs-w="3" vs-sm="12" vs-xs="12">
            <img class="logo" src="./../assets/logo.png">
          <div v-if="!finish">
              <vs-card id="main-card">
                <div>
                  <span>
                    Конвертируйте музыку из Вконтакте в Яндекс.Музыку максимально легко и быстро.
                    <br><br>
                    Просто вставьте ссылку <b>на свою страницу</b> или <b>ссылку на плейлист</b>:
                    <br>
                  </span>
                </div>
                <div slot="footer">
                    <div class="centerx default-input">
                      <vs-input size="large" class="inputx" placeholder="https://vk.com/music/album/-2000703561_9703561_1d62d863fd7dfe8bbf" v-model="url"/>
                    </div>
                </div>
              </vs-card>
              <vs-button v-if="url.length > 0" @click="getPlaylist()" id="convert" class="vs-con-loading__container"  color="primary" type="filled" ref="loadableButton">Конвертировать</vs-button>
              <vs-alert v-if="url.length > 0" active="true" color="warning" style="margin-top: 30px; height: auto;">
                В зависимости от нагрузки на сервер, продолжительность генерации плейлиста может возрасти. 1000 треков ~ 10 минут.
              </vs-alert>
          </div>

          <transition name="slide-fade">
          <div v-if="finish">
              <vs-card id="main-card">
                <div slot="media">
                  <vs-row>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" vs-sm="4" vs-xs="4">
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" vs-sm="12" vs-xs="12" style="padding-left: 10px;">
                      <div id="finish_text">
                        <p>
                          <textarea v-html="tracklist" class="tracklist" ref="tracklist">
                          </textarea>
                        </p>
                        <vs-divider/>
                      </div>
                    </vs-col>
                  </vs-row>
                </div>
                <vs-button @click="openUrl()" id="convertend" color="primary" type="filled">Скопировать и добавить треки</vs-button>
                <vs-alert v-if="showHelp" active="true" color="warning" style="margin-top: 10px; height: auto;">
                  <p>
                    Если ничего не происходит при нажатии на кнопку, выделите текст в ручную и вставьте на этой странице: <a href="https://music.yandex.ru/import/" target="_blank">https://music.yandex.ru/import/</a>
                  </p>
                </vs-alert>
              </vs-card>
          </div>
          </transition>

          <vs-alert v-if="error" title="Ошибка" active="true" color="danger" style="margin-top: 30px; height: auto;">
            К сожалению, мы не можем получить данные из плейлиста, возможно он находится в закрытой категории.
          </vs-alert>

            <div v-if="!finish" style="margin-top: 60px;">
              <vs-button @click="popupLink=true" class="api-button" line-origin="left" color="#0077ff" type="line">Как получить ссылку на плейлист?</vs-button>
              <a href="https://www.tinkoff.ru/sl/4TnDzaj7NMz" target="_blank">
                <vs-button line-origin="left" color="#0077ff" type="line">Поддержать проект</vs-button>
              </a>
              <vs-button @click="popupContact=true" class="api-button" line-origin="left" color="#0077ff" type="line">Контакты</vs-button>
              <div style="margin-top: 20px;">
                <a href="https://spotya.ru/" target="_blank" class="botml">
                  <vs-button line-origin="left" color="#0077ff" type="line">Плейлисты из Spotify</vs-button>
                </a>
                <a href="https://yt.spotya.ru/" target="_blank" class="botml">
                  <vs-button line-origin="left" color="#0077ff" type="line">Плейлисты из YT Music</vs-button>
                </a>
              </div>

              <vs-popup class="holamundo" title="Контакты" :active.sync="popupContact" icon-close="Закрыть">
                <p>
                  <b>Вконтакте:</b> https://vk.com/umnikone <br>
                  <b>Telegram:</b> @UmnikOne
                </p>
              </vs-popup>
              <vs-popup class="holamundo" title="Как получить ссылку на плейлист?" :active.sync="popupLink" icon-close="Закрыть">
                <p>
                  Откройте нужный вам плейлист, затем наведите на кнопку <b>"Ещё"</b> и нажмите на кнопку <b>"Скопировать ссылку"</b> 
                  <br><br>
                  <img class="logo" src="./../assets/vkhow.png" style="width: 100%;">
                  <br><br>
                  Вы должны получить ссылку ввида: https://vk.com/music/playlist/22917369_17
                </p>
              </vs-popup>
            </div>
            <div v-else>
                <vs-button @click="popupHow=true" line-origin="left" color="#0077ff" type="line" style="margin: 0px;">Как добавить плейлист к себе</vs-button>
              <vs-popup class="holamundo" title="Как добавить плейлист к себе?" :active.sync="popupHow" icon-close="Закрыть">
                <p>
                  После того, как вы нажали кнопку "Скопировать и добавить треки", вас автоматически перекинет на следующую страницу.<br>На ней, вы должны вставить список треков в форму и нажать кнопку "Найти".<br>
                  <i>Текст копируется автоматически, поэто можете сразу вставлять текст в форму.</i>
                  <br><br>
                  <img class="logo" src="./../assets/spotya_how1.png" style="width: 100%;">
                  <br><br>
                  После того, как поиск закончен, вы можете нажать кнопку "Сохранить в плейлист" и внизу ввести название своего плейлиста. Далее нажать кнопку "Ender". И всё, ваш плейлист готов.
                  <br><br>
                  <img class="logo" src="./../assets/spotya_how2.png" style="width: 100%;">
                </p>
              </vs-popup>
            </div>
          </vs-col>
          
      </vs-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      url: '',
      convert: false,
      backgroundLoading:'primary',  
      playlist: {},
      finish: false,
      finish_images: [],
      finish_count: 0,
      finish_link: '',
      error: false,
      feed: [],
      last: false,
      popupLink:false,
      popupHow:false,
      popupContact:false,
      tracklist: [],
      showHelp: false,
    }
  },
  methods: {
    getPlaylist() {
      this.error = false;
      this.$vs.loading({
        background: this.backgroundLoading,
        color: '#FFFFFF',
        container: this.$refs.loadableButton.$el,
        scale: 0.45
      });
      axios.post('http://vk.spotya.ru/vk.php', {
          url: this.url,
        })
        .then(response => {
          this.$vs.loading.close(this.$refs.loadableButton.$el);
          if (response.data !== 'error') {
            this.finish = true;
            this.tracklist = response.data;
          } else {
            this.error = true;
          }
          
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    openUrl() {
      this.showHelp = true;
      this.$refs.tracklist.select();
      document.execCommand('copy');
      window.open("https://music.yandex.ru/import/", "_blank");
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container { 
  height: 200px;
  position: relative;
  height: calc(100% - 140px);
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 40%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.inputx {
  width: 100%;
}

.logo {
  margin-bottom: 10px;
}

#main-card {
  padding-right: 20px !important;
  padding-top: 20px;
  padding-left: 20px;
}

#convert {
  width: 50%;
  box-shadow: 0 10px 11px -10px #0077ff;
}

#convertend {
  /* width: 50%; */
  box-shadow: 0 10px 11px -10px #0077ff;
  margin-bottom: 5px;
}

#checkImport {
  width: 50%;
  box-shadow: 0 10px 11px -10px #0077ff;
  margin: 0 auto;
  display: block;
  margin-top: 10px;
}

#checkArea {
  border-radius: 7px;
  border-color: #8e8e8e;
  width: 100%;
  height: 100px;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.vs-list--slot {
  margin-right: auto;
}

.vs-card--media img {
  margin-bottom: 20px;
}

#finish_text {
  font-size: smaller;
  width: 100%;
}

.last_playlisted {
  position: absolute;
  bottom: 0px;
}

.tracklist {
  display: block;
  width: 100%;
  height: 200px;
  border: 0;
}

.vuesax-app-is-ltr .vs-popup--close {
  font-size: 17px;
}

.api-button {
  margin-left: 10px;
  margin-right: 10px;
}

.botml {
  margin-left: 10px;
  margin-right: 10px;
}
</style>
<style>
  @import '../assets/styles.css';
</style>
